import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { ErrorMessageComponent } from '../error-message/error-message.component';

@Component({
  selector: 'cat-ai-textarea',
  standalone: true,
  imports: [
    FormlyModule,
    ReactiveFormsModule,
    MatInputModule,
    NgClass,
    ErrorMessageComponent,
  ],
  templateUrl: './textarea.component.html',
})
export class TextareaComponent extends FieldType<FieldTypeConfig> {}
