import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardComponent } from '@cat-ai-us-fe/shared/ui';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'cat-ai-form-layout',
  standalone: true,
  imports: [
    CardComponent,
    ReactiveFormsModule,
    FormlyModule,
    CommonModule,
    MatButtonModule,
  ],
  templateUrl: './form-layout.component.html',
})
export class FormLayoutComponent<Data> {
  @Input({ required: true }) form!: FormGroup;
  @Input({ required: true }) fields!: FormlyFieldConfig[];
  @Input({ required: true }) model!: Data;
  @Input() title!: string;
  @Input() size: 'sm' | 'md' | 'lg' = 'sm';

  @Output() handleSubmit = new EventEmitter<Data>();
  @Output() changeFormValue = new EventEmitter<Data>();
}
