import { Component } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FieldType } from '@ngx-formly/material';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { NgClass } from '@angular/common';
import { ErrorMessageComponent } from '../error-message/error-message.component';

@Component({
  selector: 'cat-ai-datepicker',
  standalone: true,
  imports: [
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    FormlyModule,
    ReactiveFormsModule,
    NgClass,
    ErrorMessageComponent,
  ],
  providers: [provideNativeDateAdapter()],

  templateUrl: './datepicker.component.html',
})
export class DatepickerComponent extends FieldType<FieldTypeConfig> {}
