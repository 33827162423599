import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { CardComponent } from '@cat-ai-us-fe/shared/ui';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'cat-ai-form-card-wrapper',
  standalone: true,
  imports: [NgClass, CardComponent],
  templateUrl: './form-card-wrapper.component.html',
})
export class FormCardWrapperComponent extends FieldWrapper {}
