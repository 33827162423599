import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { LazyLoadIconDirective } from '@cat-ai-us-fe/shared/util';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { ErrorMessageComponent } from '../error-message/error-message.component';

@Component({
  selector: 'cat-ai-input',
  standalone: true,
  imports: [
    FormlyModule,
    ReactiveFormsModule,
    MatInputModule,
    LazyLoadIconDirective,
    MatIconModule,
    NgClass,
    ErrorMessageComponent,
  ],
  templateUrl: './input.component.html',
})
export class InputComponent extends FieldType<FieldTypeConfig> {}
