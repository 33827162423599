<div class="flex flex-col gap-2">
  @for (field of field.fieldGroup; track field; let i = $index) {
    <div class="flex gap-2 items-center">
      <formly-field class="w-full" [field]="field"></formly-field>
      <button mat-fab (click)="add(i + 1)" color="basic">
        <mat-icon class="material-icons-outlined">add</mat-icon>
      </button>
      <button mat-fab (click)="remove(i)" color="basic">
        <mat-icon class="material-icons-outlined">close</mat-icon>
      </button>
    </div>
  }
</div>
