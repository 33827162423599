import { Component, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ErrorMessageComponent } from '../error-message/error-message.component';

@Component({
  selector: 'cat-ai-request',
  standalone: true,
  imports: [
    FormlyModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    FormlySelectModule,
    CommonModule,
    MatButtonModule,
    ErrorMessageComponent,
  ],
  templateUrl: './request.component.html',
})
export class RequestComponent extends FieldType<FieldTypeConfig> {
  @ViewChild('select') select!: MatSelect;

  open() {
    this.select.open();
  }
}
