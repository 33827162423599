<div class="flex items-center">
  <div class="relative">
    <img
      src="assets/person.png"
      alt="Person"
      height="620px"
      class="h-[620px] object-cover object-left-top"
    />

    <label class="absolute top-[230px] left-[55px]">
      <input
        id="leftArm"
        type="radio"
        class="sr-only peer"
        [formControl]="formControl"
        [formlyAttributes]="field"
        value="leftArm"
      />
      <label for="leftArm" class="cat-ai-body-part-btn"> Left Arm </label>
    </label>
    <label class="absolute top-[230px] left-[200px]">
      <input
        id="rightArm"
        type="radio"
        class="sr-only peer"
        [formControl]="formControl"
        [formlyAttributes]="field"
        value="rightArm"
      />
      <label for="rightArm" class="cat-ai-body-part-btn"> Right Arm </label>
    </label>
  </div>
</div>
