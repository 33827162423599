import { Directive } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[type="file"]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(change)': 'onChange(toArray($event.target.files))',
    '(blur)': 'onTouched()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileValueAccessorDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class FileValueAccessorDirective implements ControlValueAccessor {
  value: unknown;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_: unknown) => {};

  onTouched = () => {
    return;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  writeValue(_value: unknown) {}

  registerOnChange(fn: (_: unknown) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  toArray(fileList: FileList) {
    return Array.from(fileList);
  }
}
