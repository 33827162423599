import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'cat-ai-multiselect-option',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './multiselect-option.component.html',
})
export class MultiselectOptionComponent {
  @Input({ required: true }) label = '';
  @Input({ required: true }) index = 0;

  @Output() removeClick = new EventEmitter<number>();

  onRemoveClick(e: MouseEvent) {
    e.stopPropagation();
    this.removeClick.emit(this.index);
  }
}
