<div
  class="bg-gray-50 text-gray-800 px-2 py-1.5 rounded-sm inline-flex gap-1 items-center"
>
  <span class="text-sm leading-3 capitalize">
    {{ label }}
  </span>
  <button class="inline-flex items-center" (click)="onRemoveClick($event)">
    <mat-icon class="!size-3 !text-xs !leading-none">close</mat-icon>
  </button>
</div>
