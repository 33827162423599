import { Component, Input, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatError } from '@angular/material/form-field';

@Component({
  selector: 'cat-ai-error-message',
  standalone: true,
  imports: [MatError],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ErrorMessageComponent),
    },
  ],
  templateUrl: './error-message.component.html',
})
export class ErrorMessageComponent implements ControlValueAccessor {
  @Input({ required: true }) formControl!: FormControl;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input({ required: true }) props!: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {};
  // eslint-disable-next-line @angular-eslint/no-output-native

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  writeValue(value: any) {}

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
