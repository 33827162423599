import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'cat-ai-body-part',
  standalone: true,
  imports: [ReactiveFormsModule, FormlyModule],
  templateUrl: './body-part.component.html',
})
export class BodyPartComponent extends FieldType<FieldTypeConfig> {}
