import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { ErrorMessageComponent } from '../error-message/error-message.component';

@Component({
  selector: 'cat-ai-timepicker',
  standalone: true,
  imports: [
    FormlyModule,
    NgxMatTimepickerModule,
    NgClass,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgxMaskDirective,
    NgxMaskPipe,
    FormsModule,
    ErrorMessageComponent,
  ],
  templateUrl: './timepicker.component.html',
})
export class TimepickerComponent extends FieldType<FieldTypeConfig> {
  dispatch(value: string) {
    this.formControl.setValue(value);
  }
}
