import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { MatRadioModule } from '@angular/material/radio';
import { ErrorMessageComponent } from '../error-message/error-message.component';

@Component({
  selector: 'cat-ai-radio',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormlyModule,
    CommonModule,
    FormlySelectModule,
    MatRadioModule,
    ErrorMessageComponent,
  ],
  templateUrl: './radio.component.html',
})
export class RadioComponent extends FieldType<FieldTypeConfig> {}
