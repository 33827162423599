<div>
  <label class="block mb-1.5 font-semibold leading-3" [for]="id + 0">
    {{ props.label }}
    @if (!props.required && props.label) {
      <span class="text-gray-400">(Optional)</span>
    }
  </label>
  <div
    class="flex gap-1 flex-wrap cat-input"
    [class.cat-invalid]="formControl.invalid && formControl.touched"
  >
    <mat-radio-group
      [formControl]="formControl"
      [formlyAttributes]="field"
      aria-label="Select an option"
      [required]="field.props.required || false"
      class="cat-radio"
    >
      @for (
        option of props.options | formlySelectOptions: field | async;
        track option;
        let i = $index
      ) {
        <mat-radio-button disableRipple="true" [value]="option.value">{{
          option.label
        }}</mat-radio-button>
      }
    </mat-radio-group>
  </div>
  <cat-ai-error-message
    [formControl]="formControl"
    [props]="props"
  ></cat-ai-error-message>
</div>
