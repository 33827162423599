import { Component, OnInit } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  FormlySelectModule,
  FormlySelectOption,
} from '@ngx-formly/core/select';
import { AsyncPipe, NgClass } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ErrorMessageComponent } from '../error-message/error-message.component';

@Component({
  selector: 'cat-ai-autocomplete',
  standalone: true,
  imports: [
    MatAutocompleteModule,
    ReactiveFormsModule,
    FormlySelectModule,
    FormlyModule,
    AsyncPipe,
    MatInputModule,
    FormsModule,
    NgClass,
    MatIconModule,
    ErrorMessageComponent,
  ],
  templateUrl: './autocomplete.component.html',
})
export class AutocompleteComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  searchControl!: FormControl;

  ngOnInit(): void {
    this.searchControl = this.form.get('autocomplete_search') as FormControl;
  }

  select(option: FormlySelectOption) {
    const addedSelections: FormlySelectOption[] = this.formControl.value || [];
    const optionExist = addedSelections.find((el) => el === option);
    if (optionExist) {
      return;
    }
    this.formControl.setValue([...addedSelections, option]);
    this.searchControl.setValue(option.label, { emitModelToViewChange: false });
  }

  remove(e: MouseEvent, index: number) {
    e.preventDefault();
    const value = this.formControl?.value || [];
    value.splice(index, 1);
    this.formControl.updateValueAndValidity();
  }
}
