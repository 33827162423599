import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findOption',
  standalone: true,
})
export class FindOptionPipe implements PipeTransform {
  transform(optionId: number, opts: unknown) {
    const options = opts as { label: string; value: number }[];
    return options?.find((option) => option.value === optionId);
  }
}
