@if (formControl.errors && formControl.touched) {
  @if (formControl.errors.required) {
    <mat-error>This field is required.</mat-error>
  }
  @if (formControl.errors.min) {
    <mat-error>Minimum value is {{ props.min }}.</mat-error>
  }
  @if (formControl.errors.max) {
    <mat-error>Maximum value is {{ props.max }}.</mat-error>
  }
  @if (formControl.errors.minLength) {
    <mat-error>Minimum {{ props.minLength }} characters required.</mat-error>
  }
  @if (formControl.errors.maxLength) {
    <mat-error>Maximum {{ props.maxLength }} characters allowed.</mat-error>
  }
  @if (formControl.errors.customError) {
    <mat-error>{{ formControl.errors.customError }}</mat-error>
  }
}
