<div>
  <span
    class="block mb-1.5 font-semibold leading-3 cursor-default text-base-muted"
    (click)="open()"
    [id]="id + '_label'"
  >
    {{ props.label }}
  </span>

  <mat-form-field class="w-full">
    <mat-select
      #select
      [formControl]="formControl"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder || ''"
      [aria-labelledby]="id + '_label'"
      [id]="id"
    >
      @for (
        option of props.options | formlySelectOptions: field | async;
        track option
      ) {
        <mat-option [value]="option.value">{{ option.label }}</mat-option>
      }
    </mat-select>
    <mat-icon matSuffix class="!pl-0">keyboard_arrow_down</mat-icon>
  </mat-form-field>
  <cat-ai-error-message
    [formControl]="formControl"
    [props]="props"
  ></cat-ai-error-message>
</div>
