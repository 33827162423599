import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'cat-ai-repeat',
  standalone: true,
  imports: [FormlyModule, MatIconModule, MatButtonModule],
  templateUrl: './repeat.component.html',
})
export class RepeatComponent extends FieldArrayType {}
